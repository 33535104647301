import Transformer from 'services/transform/resource/transformers/Transformer';

class VotingTransformer extends Transformer {
    static transform(voting, meta) {
        const {...result} = voting;

        return result;
    }
}

export default VotingTransformer;