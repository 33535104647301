import React from "react";
import {Box, Divider, Typography} from "@mui/material";
import CloseIcon from "@/icons/Close";
import styles from "../styles/styles";
import InfoIcon from 'icons/Info';
import Button from "@mui/material/Button";
import {useLang} from "providers/LangProvider/LangProvider";
import {parseToHTML} from "@/services/formatters/parseToHtml/parseToHtml";

const StaticAnnouncements = (props) => {
    const {announcements, onClose} = props;

    const {lang} = useLang();

    if (_.isEmpty(announcements)) {
        return null;
    }

    const redirectToUrl = (url) => {
        window.open(url, "_blank");
    }

    const getTranslation = (announcement) => {
        const translation = announcement?.translations.find(t => t.lang === lang);

        return ({
            title: translation?.title,
            content: translation?.content,
            btn_label: translation?.btn_label
        });
    }

    return (
        <Box sx={styles.announcementContentBox}>
            <Box sx={styles.announcementBox}>
                <Box sx={styles.infoIcon}>
                    <InfoIcon/>
                </Box>
                <Box width={'100%'} sx={styles.announcementsRootBlock}>
                    <Box sx={styles.announcements}>
                        {
                            announcements.map((announcement, index) => {

                                return (
                                    <Box key={index}>
                                        <Box sx={styles.staticAnnouncementContent}>
                                            <Box>
                                                <Typography variatn={'p3Bold'} sx={styles.name}>{getTranslation(announcement)?.title}</Typography>
                                                <Typography
                                                    sx={styles.content}
                                                    variant={'p4'}
                                                    dangerouslySetInnerHTML={{__html: parseToHTML(getTranslation(announcement)?.content)}}
                                                />
                                            </Box>
                                            <Box sx={styles.closeIconBox} onClick={() => onClose(announcement)}>
                                                <CloseIcon color={'primary'}/>
                                            </Box>
                                        </Box>
                                        {
                                            announcement?.btn_url &&
                                            <Button
                                                sx={styles.urlButton}
                                                type={'button'}
                                                variant={'contained'}
                                                color={'secondary'}
                                                onClick={() => redirectToUrl(announcement?.btn_url)}
                                            >
                                                <Typography variant={'p4'}>{getTranslation(announcement)?.btn_label}</Typography>
                                            </Button>
                                        }
                                        {(announcements.length - 1 !== index) && <Divider sx={styles.divider}/>}
                                    </Box>
                                )
                            })
                        }
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default StaticAnnouncements;