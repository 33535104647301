import React, {createContext, useContext, useEffect, useState} from "react";
import {createSeenAnnouncement, getAnnouncements} from "@/services/api/announcement/announcement";
import {AnnouncementTransformer} from "@/services/transform/resource/transformers/transformers";
import {Resource} from "@/services/transform/resource/resource";
import {SHOW_TYPE} from "@/components/Announcement/staticData/staticData";
import notification from "@/services/notification/notification";
import useTranslate from "@/components/UseTranslate/useTranslate";

export const AnnouncementsContext = createContext({});

const Announcements = ({children}) => {
    const [announcements, setAnnouncements] = useState([]);
    const {t} = useTranslate()

    useEffect(() => {
        load()
            .then(announcements => setAnnouncements(announcements))
            .catch(() => notification.warning(t('something_wrong')));
    }, []);

    const load = async () => {
        let response = await getAnnouncements({include: ['translations']});
        let announcements = Resource.transform(AnnouncementTransformer, response.data).data;

        return announcements.map(announcement => {
            return {...announcement, has_seen: false, is_closed: false}
        })
    }

    const closeStaticAnnouncements = () => {
        let modifiedAnnouncements = [...announcements].map(announcement => {
            announcement.is_closed = true;

            return announcement;
        });

        setAnnouncements(modifiedAnnouncements);
    }

    const closeAnnouncement = (closedAnnouncement) => {
        let modifiedAnnouncements = [...announcements].map(announcement => {
            if (announcement.id === closedAnnouncement.id) {
                announcement.is_closed = true;
            }
            return announcement;
        });

        if (closedAnnouncement.show_type === SHOW_TYPE.once) {
            createSeenAnnouncement(closedAnnouncement.id).then(() => {
            });
        }
        setAnnouncements(modifiedAnnouncements);
    }

    return (
        <AnnouncementsContext.Provider value={{announcements, closeStaticAnnouncements, closeAnnouncement}}>
            {children}
        </AnnouncementsContext.Provider>
    )
}

export default Announcements;

export const useAnnouncements = () => {
    return useContext(AnnouncementsContext);
};