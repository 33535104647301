import React, {createContext, useContext, useEffect, useState} from "react";

import {getVotings, updateVotingSeen, updateVotingVoted} from "modules/UnionMember/services/api/voting/voting";
import {VotingTransformer} from "modules/UnionMember/services/transform/transform";
import {Resource} from "services/transform/transform";
import notification from "services/notification/notification";
import useTranslate from "components/UseTranslate/useTranslate";

export const VotingsContext = createContext({});

const VotingsProvider = ({children}) => {
    const [votings, setVotings] = useState([]);

    const {t} = useTranslate()

    useEffect(() => {
        load()
            .then(votings => setVotings(votings))
            .catch(() => notification.warning(t('something_wrong')));
    }, []);

    const load = async () => {
        let response = await getVotings({include: ['translations']});
        let votings = Resource.transform(VotingTransformer, response.data).data;

        return votings.map(voting => {
            return {...voting, is_closed: false}
        })
    }

    const closeVoting = (closedVoting) => {
        let modifiedVotings = [...votings].map(voting => {

            if (voting.id === closedVoting.id) {
                voting.is_closed = true;
            }

            return voting;
        });

        if (!closedVoting.seen) {
            updateVotingSeen(closedVoting.id).then(() => {
            });
        }

        setVotings(modifiedVotings);
    }

    const handleVote = async (votedVoting) => {
        let modifiedVotings = [...votings].map(voting => {

            if (voting.id === votedVoting.id) {
                voting.is_closed = true;
            }

            return voting;
        });

        await updateVotingVoted(votedVoting.id).then(() => {
        });

        setVotings(modifiedVotings);
    }

    return (
        <VotingsContext.Provider value={{votings, closeVoting, handleVote}}>
            {children}
        </VotingsContext.Provider>
    )
}

export default VotingsProvider;

export const useVotings = () => {
    return useContext(VotingsContext);
};