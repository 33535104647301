import React, {useEffect, useState} from "react"
import {CircularProgress, Container} from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ActiveForm, {ActiveInput} from "@/components/ActiveForm/ActiveForm";
import useTranslate from "@/components/UseTranslate/useTranslate";
import {loginAudkenni, verifyAudkenni} from "@/services/api/auth/auth";
import styles from "@/pages/Login/styles/styles";
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import {useAuth} from "@/providers/Auth/AuthProvider";
import * as Yup from "yup";
import {Alert} from "@mui/lab";
import WarningOutlined from "@/icons/WarningOutlined";
import {API_STATUSES} from "@/services/api/statuses/statuses";
import notification from "@/services/notification/notification";
import useMediaQuery from "@mui/material/useMediaQuery";
import theme from "@/themes/main/main";
import {useLang} from "@/providers/LangProvider/LangProvider";


const TAB_VALUES = {
    PHONE: 1,
    APP: 2,
}
const LOGIN_STATES = {
    STARTED: 0,
    PHONE_EXPIRED: 1,
    APP_EXPIRED: 2,
    PHONE_FAILED: 3,
    APP_FAILED: 4,
}

const AudkenniForm = () => {
    const [tabValue, setTabValue] = useState(TAB_VALUES.PHONE);
    const [loginState, setLoginState] = useState(null);
    const [verificationData, setVerificationData] = useState({token: null, code: null});

    const {accountInterface} = useAuth();
    const {lang} = useLang();
    const {t} = useTranslate();

    const sm = useMediaQuery(theme.breakpoints.down('md'));

    const isIPhone = /iPhone/.test(navigator.userAgent);

    const isPhoneLogin = (tabValue === TAB_VALUES.PHONE);
    const isAppLogin = (tabValue === TAB_VALUES.APP);


    useEffect(() => {
        const handleVisibilityChange = () => {
            if (isIPhone && isAppLogin && document.visibilityState === 'visible' && loginState === LOGIN_STATES.STARTED && verificationData.token) {
                verify(verificationData.token, verificationData.code);
            }
        };

        document.addEventListener('visibilitychange', handleVisibilityChange);
        return () => document.removeEventListener('visibilitychange', handleVisibilityChange);
    }, [loginState, verificationData]);

    const login = async (data, lang) => {
        const response = await loginAudkenni(data, lang);

        return response.data;
    }

    function verify(token, code) {
        let codeString = `${code}`
        const data = {token, code: codeString};

        verifyAudkenni(data).then(() => {
            window.location.href = '/';
        }).catch((error) => {
            const {status} = error;

            if (+status === API_STATUSES.UNAUTHORIZED) {
                setLoginState(isPhoneLogin ? LOGIN_STATES.PHONE_FAILED : LOGIN_STATES.APP_FAILED);
            } else {
                setLoginState(isPhoneLogin ? LOGIN_STATES.PHONE_EXPIRED : LOGIN_STATES.APP_EXPIRED);
            }
        });
    }

    const handleSubmit = async (values, form) => {
        setLoginState(LOGIN_STATES.STARTED);
        const phone = values.phone.replaceAll('-', '');
        const ssn = values.ssn.replaceAll('-', '');

        const loginData = isPhoneLogin ? {phone: `+354${phone}`} : {ssn}

        await login(loginData, lang).then(resp => {
            const {token, code} = resp;

            if (isIPhone && isAppLogin) {
                setVerificationData({token, code});
                return;
            }

            verify(token, code);
        })
    }

    const handleChange = (event, newValue) => {
        if (loginState === LOGIN_STATES.STARTED) {
            return;
        }
        setTabValue(newValue);
    };

    return (
        <Container>
            <Box pt={sm ? 3 : 5}>
                <Typography variant={sm ? 'h4' : 'h1'} sx={styles.audkenni_header}>
                    {t('title_audkenni', {union: accountInterface.account?.name})}
                </Typography>
            </Box>
            <Box textAlign={'center'} mt={sm ? 1 : 2} mb={sm ? 3 : 5}>
                <Typography variant={sm ? 'p3' : 'p2'}
                            sx={styles.audkenni_header}>{t('sub_audkenni')}</Typography>
            </Box>
            <Box sx={styles.audkenni}>
                <Box sx={styles.audkenni_login_box}>
                    <ActiveForm
                        initialValues={{
                            ssn: '',
                            phone: ''
                        }}
                        validationSchema={Yup.object().shape({
                            phone: Yup.string().matches(/^\d{3}-\d{4}$/, 'invalid'),
                            ssn: Yup.string().matches(/^\d{6}-\d{4}$/, 'invalid'),
                        })}
                        onSubmit={(values, form) => handleSubmit(values, form)}
                        onError={() => {
                            notification.warning(t('login_failed'));
                            setLoginState(null);
                        }}
                        render={(form) => {
                            const {
                                values,
                                errors,
                                touched,
                                isSubmitting,
                                setFieldValue,
                                handleSubmit,
                                isValid
                            } = form;


                            return (
                                <form onSubmit={handleSubmit}>
                                    <TabContext value={tabValue} mt={3}>
                                        <Box>
                                            <TabList sx={styles.audkenni_tabList} onChange={handleChange}>
                                                <Tab
                                                    sx={styles.audkenni_tab}
                                                    label={t('tab_sim')}
                                                    value={TAB_VALUES.PHONE}
                                                />
                                                <Tab
                                                    sx={styles.audkenni_tab}
                                                    label={t('tab_app')}
                                                    value={TAB_VALUES.APP}
                                                />
                                            </TabList>
                                        </Box>
                                        <TabPanel value={TAB_VALUES.PHONE} sx={styles.audkenni_tab_panel}>
                                            <Box mt={4} mb={4}>
                                                <ActiveInput
                                                    type="phone"
                                                    name="phone"
                                                    placeholder={t('placeholder_phone')}
                                                    label={t('phone')}
                                                    error={!!(touched.phone && errors.phone)}
                                                    value={values.phone}
                                                    onChange={(name, value) => {
                                                        let phoneNumber = value.replaceAll('-', '');

                                                        if (phoneNumber.length > 3) {
                                                            const parts = [];
                                                            parts.push(phoneNumber.slice(0, 3));
                                                            parts.push(phoneNumber.slice(3, 7));
                                                            phoneNumber = parts.join('-');
                                                        }
                                                        setFieldValue(name, phoneNumber);
                                                    }}
                                                    margin='normal'
                                                    onError={error => t(`error_phone_${error}`)}
                                                    fullWidth
                                                />
                                            </Box>
                                            {
                                                loginState === LOGIN_STATES.STARTED &&
                                                <WaitingBox message={t('login_waiting')}/>
                                            }
                                            {
                                                loginState === LOGIN_STATES.PHONE_FAILED &&
                                                <ResponseBox message={t('sim_member_not_found')}/>
                                            }
                                            {
                                                loginState === LOGIN_STATES.PHONE_EXPIRED &&
                                                <ResponseBox message={t('login_timed_out')}/>
                                            }
                                        </TabPanel>
                                        <TabPanel value={TAB_VALUES.APP} sx={styles.audkenni_tab_panel}>
                                            <Box mt={4} mb={4}>
                                                <ActiveInput
                                                    type="text"
                                                    name="ssn"
                                                    placeholder={t('placeholder_ssn')}
                                                    label={t('ssn')}
                                                    error={!!(touched.ssn && errors.ssn)}
                                                    value={values.ssn}
                                                    onChange={(name, value) => {
                                                        let ssn = value.replaceAll('-', '');

                                                        if (ssn.length > 6) {
                                                            const parts = [];
                                                            parts.push(ssn.slice(0, 6));
                                                            parts.push(ssn.slice(6, 10));
                                                            ssn = parts.join('-');
                                                        }
                                                        setFieldValue(name, ssn);
                                                    }}
                                                    margin='normal'
                                                    onError={error => t(`error_phone_${error}`)}
                                                    fullWidth
                                                />
                                            </Box>
                                            {
                                                loginState === LOGIN_STATES.STARTED &&
                                                <WaitingBox message={t('login_waiting')}/>
                                            }
                                            {
                                                loginState === LOGIN_STATES.APP_FAILED &&
                                                <ResponseBox message={t('app_member_not_found')}/>
                                            }
                                            {
                                                loginState === LOGIN_STATES.APP_EXPIRED &&
                                                <ResponseBox message={t('login_timed_out')}/>
                                            }

                                            <Box mb={4}>
                                                <Typography variant={'p4'}>
                                                    {t('app_description')}
                                                    <Link href="https://www.audkenni.is/" color={'primary'}
                                                          target="_blank">
                                                        {t('link_audkenni_website')}
                                                    </Link>
                                                </Typography>
                                            </Box>
                                        </TabPanel>
                                    </TabContext>
                                    <Box sx={styles.audkenni_login_box_footer}>
                                        <Button
                                            fullWidth={true}
                                            disabled={isSubmitting || !isValid || loginState === LOGIN_STATES.STARTED}
                                            variant="contained"
                                            color="primary"
                                            type="submit"
                                        >
                                            {t('login')}
                                        </Button>
                                    </Box>
                                </form>
                            )
                        }}
                    />
                </Box>
            </Box>

            <Box display={'flex'} justifyContent={'center'} mt={3} mb={5}>
                <Typography sx={styles.audkenni_privacy}>
                    {
                        t('privacy_policy_login', {
                            privacy: `<a href=${accountInterface.account?.privacy_policy_url ?? '#'}>${t('privacy_policy')}</a>`,
                            union: accountInterface?.account?.name
                        }, true)
                    }
                </Typography>
            </Box>
        </Container>
    )
}

const WaitingBox = ({message}) => {
    return (
        <Box sx={styles.audkenni_sim_response_box}>
            <CircularProgress sx={styles.audkenni_circular}/>
            <Typography sx={styles.audkenni_sim_waiting_title}>
                {message}
            </Typography>
        </Box>
    )
}

const ResponseBox = ({message}) => {
    return (
        <Box sx={styles.audkenni_sim_response_box}>
            <Alert
                sx={styles.audkenni_sim_timeout_alert}
                icon={<WarningOutlined sx={(theme) => ({fill: theme.palette.common.white})}/>}
            />
            <Box>
                <Typography component={'p'} variant="p3Bold" ml={1.5}>
                    {message}
                </Typography>
            </Box>
        </Box>
    )
}

export default AudkenniForm;