import React from "react";
import {parseToHTML} from "@/services/formatters/parseToHtml/parseToHtml";
import {Box, Divider, Typography} from "@mui/material";
import CloseIcon from "@/icons/Close";
import styles from "../styles/styles";
import InfoIcon from 'icons/Info';
import Button from "@mui/material/Button";
import storage from "@/services/storage/storage";

const StaticAnnouncements = (props) => {
    const {announcements, onClose} = props;
    const lang = storage.get('lang');

    if (_.isEmpty(announcements)) {
        return null;
    }

    const redirectToUrl = (url) => {
        window.open(url, "_blank");
    }

    const getTranslation = (announcement) => {
        return announcement?.translations.find((translation) => translation.lang === lang)?.name;
    }

    return (
        <Box sx={styles.announcementContentBox}>
            <Box sx={styles.announcementBox}>
                <Box sx={styles.infoIcon}>
                    <InfoIcon/>
                </Box>
                <Box width={'100%'} sx={styles.announcementsRootBlock}>
                    <Box sx={styles.closeIconBox} onClick={onClose}>
                        <CloseIcon color={'primary'}/>
                    </Box>
                    <Box sx={styles.announcements}>
                        {
                            announcements.map((announcement, index) => {

                                return (
                                    <Box key={index}>
                                        <Box>
                                            <Typography variatn={'p3Bold'}
                                                        sx={styles.name}>{announcement.name}</Typography>
                                            <Typography
                                                sx={styles.content}
                                                variant={'p4'}
                                                dangerouslySetInnerHTML={{__html: parseToHTML(announcement.content)}}
                                            />
                                        </Box>
                                        {
                                            getTranslation(announcement) &&
                                            <Button sx={styles.urlButton} type={'button'}
                                                    variant={'contained'} color={'secondary'}
                                                    onClick={() => redirectToUrl(announcement?.btn_url)}
                                            >
                                                <Typography variant={'p4'}>{getTranslation(announcement)}</Typography>
                                            </Button>
                                        }
                                        {(announcements.length - 1 !== index) && <Divider sx={styles.divider}/>}
                                    </Box>
                                )
                            })
                        }
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default StaticAnnouncements;