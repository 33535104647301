import React from "react";
import {Box, Button, Dialog, Typography} from "@mui/material";
import styles from "@/components/Announcement/styles/styles";
import useTranslate from "@/components/UseTranslate/useTranslate";
import {useLang} from "@/providers/LangProvider/LangProvider";
import {REPETITION} from "@/components/Announcement/staticData/staticData";
import {parseToHTML} from "@/services/formatters/parseToHtml/parseToHtml";

const AnnouncementModal = (props) => {
    const {announcement, open, onClose} = props;

    const {t} = useTranslate();
    const {lang} = useLang();

    const getTranslation = (announcement) => {
        const translation = announcement?.translations.find(t => t.lang === lang);

        return ({
            title: translation?.title,
            content: translation?.content,
            btn_label: translation?.btn_label
        });
    }

    const redirectToUrl = (url) => {
        window.open(url, "_blank");
    }

    return (
        <Dialog
            open={open}
            maxWidth={false}
            className={'modal'}
            sx={styles.announcementModal}
            onClose={announcement?.repetition === REPETITION.once ? null : onClose}
        >
            <Box className={'modalHeader'}>
                <Box className={'modalHeaderInner'}>
                    <Typography variant={'h4'}>{getTranslation(announcement)?.title}</Typography>
                </Box>
            </Box>
            <Box className={'modalBody'}>
                <Typography
                    variant={'p3'}
                    dangerouslySetInnerHTML={{__html: parseToHTML(getTranslation(announcement)?.content)}}
                />
            </Box>
            <Box className={'modalFooter'}>
                <Box className={'modalFooterInner'}>
                    <Box sx={styles.announcementModalButtons}>
                        <Button
                            type={'button'}
                            variant={'contained'}
                            color={'secondary'}
                            onClick={onClose}
                            fullWidth
                        >
                            {t('close')}
                        </Button>
                        {
                            announcement?.btn_url &&
                            <Button
                                type={'button'}
                                variant={'contained'}
                                color={'primary'}
                                fullWidth
                                onClick={() => redirectToUrl(announcement?.btn_url)}
                            >
                                <Typography variant={'p4'}>{getTranslation(announcement)?.btn_label}</Typography>
                            </Button>
                        }
                    </Box>
                </Box>
            </Box>
        </Dialog>
    )
}

export default AnnouncementModal;