import Transformer from 'services/transform/resource/transformers/Transformer';

class FaqCategoriesTransformer extends Transformer {
    static transform(categories) {
        const {questions, ...result} = categories;

        if (questions) {
            result.questions = questions.data;
        }

        return result;
    }
}

export default FaqCategoriesTransformer;