import {
    openSans,
    openSansBold,
    openSansBoldItalic,
    openSansExtraBold,
    openSansExtraBoldItalic,
    openSansItalic,
    openSansLight,
    openSansLightItalic,
    openSansSemiBold,
    openSansSemiBoldItalic
} from '../fonts/fonts';

export const name = 'main';

export const breakpoints = {
    values: {
        xs: 0,
        sm: 375,
        md: 768,
        lg: 1440,
    },
}

export const palette = {
    common: {
        white: '#ffffff',
        black: '#000000',
    },
    primary: {
        main: '#2563EB',
    },
    error: {
        main: '#DC1313',
    },
    success: {
        main: '#2AD000',
    },
    warning: {
        main: '#FF9900',
    },
    grey: {
        50: '#F9FAFB',
        100: '#F3F4F6',
        200: '#E5E7EB',
        300: '#D1D5DB',
        400: '#9CA3AF',
        500: '#6B7280',
        600: '#4B5563',
        700: '#374151',
        800: '#1F2937',
        900: '#111827',
    },
    background: {
        default: '#F9FAFB',
    },
    hexToRGBA: (hex, alpha) => {
        hex = hex.substr(1);

        if (hex.length === 3) {
            hex = hex.split('').map(function (hex) {
                return hex + hex;
            }).join('');
        }

        const r = parseInt(hex.slice(0, 2), 16),
            g = parseInt(hex.slice(2, 4), 16),
            b = parseInt(hex.slice(4, 6), 16);

        if (alpha) {
            return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
        } else {
            return "rgb(" + r + ", " + g + ", " + b + ")";
        }
    },
};

export const typography = {
    fontFamily: 'OpenSans',
    h1: {
        fontFamily: 'OpenSansBold',
        fontSize: 56,
        lineHeight: '76px',
        color: palette.grey[800],
    },
    h2: {
        fontFamily: 'OpenSansBold',
        fontSize: 32,
        lineHeight: '44px',
        color: palette.grey[800],
    },
    h3: {
        fontFamily: 'OpenSansBold',
        fontSize: 24,
        lineHeight: '34px',
        color: palette.grey[800],
    },
    h4: {
        fontFamily: 'OpenSansBold',
        fontSize: 22,
        lineHeight: '30px',
        color: palette.grey[800],
    },
    p1: {
        fontSize: 20,
        lineHeight: '28px',
        color: palette.grey[700],
    },
    p2: {
        fontSize: 18,
        lineHeight: '26px',
        color: palette.grey[700],
    },
    p3: {
        fontSize: 16,
        lineHeight: '22px',
        color: palette.grey[700],
    },
    p4: {
        fontSize: 14,
        lineHeight: '20px',
        color: palette.grey[700],
    },
    p5: {
        fontSize: 12,
        lineHeight: '16px',
        color: palette.grey[700],
    },
    p1Bold: {
        fontFamily: 'OpenSansBold',
        fontSize: 20,
        lineHeight: '28px',
        color: palette.grey[700],
    },
    p2Bold: {
        fontFamily: 'OpenSansBold',
        fontSize: 18,
        lineHeight: '26px',
        color: palette.grey[700],
    },
    p3Bold: {
        fontFamily: 'OpenSansBold',
        fontSize: 16,
        lineHeight: '22px',
        color: palette.grey[700],
    },
    p4Bold: {
        fontFamily: 'OpenSansBold',
        fontSize: 14,
        lineHeight: '20px',
        color: palette.grey[700],
    },
    p5Bold: {
        fontFamily: 'OpenSansBold',
        fontSize: 12,
        lineHeight: '16px',
        color: palette.grey[700],
    },
};

export const components = {
    MuiCssBaseline: {
        styleOverrides: `
         ${openSans}
         ${openSansBold}
         ${openSansBoldItalic}
         ${openSansExtraBold}
         ${openSansExtraBoldItalic}
         ${openSansItalic}
         ${openSansLight}
         ${openSansLightItalic}
         ${openSansSemiBold}
         ${openSansSemiBoldItalic}
         `,
    },
    MuiButton: {
        styleOverrides: {
            root: {
                fontFamily: 'OpenSansBold',
                fontSize: 16,
                padding: `13px 16px`,
                borderRadius: 3,
                textTransform: 'capitalize',
                lineHeight: 'normal',

                '& .MuiLoadingButton-loadingIndicator': {
                    color: palette.grey[50],
                }
            },
            label: {
                lineHeight: '22px',
            },
            contained: {
                boxShadow: 'none',
            },
            containedPrimary: {
                color: palette.grey[50],


                '& .MuiTypography-root': {
                    color: palette.grey[50],
                },

                "&:hover": {
                    background: `linear-gradient(0deg, 
                ${palette.hexToRGBA(palette.common.white, 0.24)},
                ${palette.hexToRGBA(palette.common.white, 0.24)}
                ), ${palette.primary.main}`,
                    boxShadow: 'none',
                    backgroundColor: palette.primary.main,
                },

                '&.Mui-disabled': {
                    backgroundColor: palette.primary.main,
                    opacity: 0.4,
                    color: palette.grey[50],

                    '&.MuiLoadingButton-loading': {
                        opacity: 1,
                    },
                },
            },
            containedSecondary: {
                color: palette.grey[700],
                backgroundColor: palette.grey[50],
                border: `1px solid ${palette.grey[300]}`,

                "&:hover": {
                    boxShadow: 'none',
                    backgroundColor: palette.grey[100],
                },

                '&.Mui-disabled': {
                    backgroundColor: palette.grey[50],
                    opacity: 0.4,
                    color: palette.grey[700],

                    '&.MuiLoadingButton-loading': {
                        opacity: 1,
                    },
                },

                '& .MuiLoadingButton-loadingIndicator': {
                    color: palette.primary.main,
                }
            },
            containedError: {
                color: palette.grey[50],

                "&:hover": {
                    background: `linear-gradient(0deg, 
                ${palette.hexToRGBA(palette.common.white, 0.24)},
                ${palette.hexToRGBA(palette.common.white, 0.24)}
                ), ${palette.error.main}`,
                    boxShadow: 'none',
                    backgroundColor: palette.error.main,
                },

                '&.Mui-disabled': {
                    backgroundColor: palette.error.main,
                    opacity: 0.4,
                    color: palette.grey[50],

                    '&.MuiLoadingButton-loading': {
                        opacity: 1,
                    },
                },
            },
        },
    },
    MuiPaper: {
        styleOverrides: {
            root: {
                boxShadow: 'none',
                backgroundColor: 'transparent'
            }
        }
    },
    MuiContainer: {
        styleOverrides: {
            root: {
                maxWidth: breakpoints.values.lg,

                [`@media (max-width:${breakpoints.values.lg}px)`]: {
                    maxWidth: '100% !important',
                },
            }
        }
    },
    MuiInputLabel: {
        styleOverrides: {
            root: {
                ...typography.p4Bold,
                color: palette.grey[700],
                marginBottom: 4,

                '&.Mui-disabled': {
                    color: palette.hexToRGBA(palette.grey[700], 0.4),

                    '& .MuiInputLabel-asterisk': {
                        color: palette.hexToRGBA(palette.error.main, 0.4),
                    },
                },
            },
            asterisk: {
                color: palette.error.main,
            },
        },
    },
    MuiOutlinedInput: {
        styleOverrides: {
            root: {
                backgroundColor: palette.common.white,

                "&.Mui-focused": {
                    "& .MuiOutlinedInput-notchedOutline": {
                        border: `1px solid ${palette.grey[200]}`,
                    },

                    '&:not(.Mui-readOnly):not(.Mui-error)': {
                        "& .MuiOutlinedInput-notchedOutline": {
                            border: `1px solid ${palette.primary.main}`,
                            boxShadow: `0 0 4px 0 ${palette.hexToRGBA(palette.primary.main, 0.4)}`,
                        },
                    },
                },

                '&.Mui-error .MuiOutlinedInput-notchedOutline': {
                    border: `1px solid ${palette.error.main}`,
                },

                "&:hover": {
                    "&.Mui-focused": {
                        '&:not(.Mui-readOnly):not(.Mui-error)': {
                            "& .MuiOutlinedInput-notchedOutline": {
                                border: `1px solid ${palette.primary.main}`,
                                boxShadow: `0 0 4px 0 ${palette.hexToRGBA(palette.primary.main, 0.4)}`,
                            },
                        },
                    },

                    "& .MuiOutlinedInput-notchedOutline": {
                        border: `1px solid ${palette.grey[200]}`,
                    },

                    '&.Mui-error .MuiOutlinedInput-notchedOutline': {
                        border: `1px solid ${palette.error.main}`,
                    },
                },

                '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
                    border: `1px solid ${palette.hexToRGBA(palette.grey[200], 0.4)}`,
                },

                '&.search-input': {
                    backgroundColor: palette.common.white,
                    paddingLeft: 18,

                    '& .MuiSvgIcon-root': {
                        fontSize: 20,
                        color: palette.grey[400],
                    },
                    input: {
                        paddingLeft: 2,
                    },
                    '&.Mui-disabled': {
                        backgroundColor: palette.grey[50],

                        '& .MuiSvgIcon-root': {
                            color: palette.hexToRGBA(palette.grey[300], 0.4),
                        },
                    },
                },
            },
            input: {
                ...typography.p3,
                backgroundColor: palette.common.white,
                padding: '13px 16px',
                color: palette.grey[700],
                borderRadius: 3,

                '&::placeholder': {
                    ...typography.p3,
                    color: palette.grey[400],
                },

                '&.Mui-disabled': {
                    backgroundColor: palette.grey[50],
                    color: palette.hexToRGBA(palette.grey[700], 0.4),

                    '&::placeholder': {
                        ...typography.p3,
                        color: palette.hexToRGBA(palette.grey[400], 0.4),
                    },
                },
            },
            notchedOutline: {
                border: `1px solid ${palette.grey[200]}`,
            },
        },
    },
    MuiFormHelperText: {
        styleOverrides: {
            root: {
                ...typography.p5,
                color: palette.grey[700],
                marginTop: 4,

                '&.Mui-disabled': {
                    color: palette.hexToRGBA(palette.grey[700], 0.4),
                },
            },
        },
    },
    MuiModal: {
        styleOverrides: {
            root: {
                '&.modal': {
                    background: palette.hexToRGBA(palette.grey[900], 0.64),
                    '& .MuiPaper-root': {
                        border: `1px solid ${palette.grey[200]}`,
                        boxShadow: `0px 4px 16px ${palette.hexToRGBA(palette.common.black, 0.04)}`,
                        borderRadius: 7,
                        background: palette.common.white,
                        boxSizing: 'border-box',
                        overflow: 'visible',
                    },

                    [`@media (max-width: ${breakpoints.values.md}px)`]: {
                        '& .MuiPaper-root': {
                            margin: 0,
                            width: '100%',
                            maxWidth: '100%',
                            borderBottomRightRadius: 0,
                            borderBottomLeftRadius: 0,
                            maxHeight: `calc(100% + 10px)`,
                        }
                    },

                    '& .modalHeader': {
                        padding: '24px 24px 0 24px',

                        '& .modalHeaderInner': {
                            borderBottom: `1px solid ${palette.grey[200]}`,
                            paddingBottom: 24,
                        },
                    },
                    '& .modalBody': {
                        padding: 24,
                        overflowY: "scroll",
                        '&::-webkit-scrollbar': {display: 'none'},
                        msOverflowStyle: 'none',
                        scrollbarWidth: 'none',
                    },
                    '& .modalFooter': {
                        padding: 24,
                        borderTop: `1px solid ${palette.grey[200]}`,
                        backgroundColor: palette.grey[50],
                        '& .modalFooterInner': {
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        },
                    },
                    '& .MuiDialog-container': {
                        [`@media (max-width: ${breakpoints.values.md}px)`]: {
                            alignItems: 'flex-end',
                        }
                    }
                },

            },
        }
    },
    MuiCheckbox: {
        styleOverrides: {
            root: {
                '& .MuiSvgIcon-root': {
                    color: palette.grey[200],
                },
                '&.Mui-checked': {
                    '& .MuiSvgIcon-root': {
                        color: palette.primary.main,
                    },
                },
                '& + .MuiTypography-root': {
                    color: palette.primary.main,
                    textTransform: 'capitalize',
                },
                '&.Mui-disabled': {
                    opacity: 0.4
                }
            }
        }
    },
    MuiRadio: {
        styleOverrides: {
            root: {
                '& .MuiSvgIcon-root': {
                    color: palette.grey[200],
                },
                '&.Mui-checked': {
                    '& .MuiSvgIcon-root': {
                        color: palette.primary.main,
                    }
                },
                '& + .MuiTypography-root': {
                    color: palette.primary.main,
                    textTransform: 'capitalize',
                },
                '&.Mui-disabled': {
                    opacity: 0.4
                }
            }
        }
    },
    MuiDivider: {
        styleOverrides: {
            root: {
                borderColor: palette.grey[200],
            },
        }
    },
    MuiLink: {
        styleOverrides: {
            root: {
                textDecoration: 'none',
                cursor: 'pointer',

                '&:hover': {
                    opacity: 0.76,
                },

                '&.dark': {
                    color: palette.grey[700],

                    '&:hover': {
                        color: palette.grey[500],
                    },
                },
            },
        },
    },
    MuiTooltip: {
        styleOverrides: {
            tooltip: {
                backgroundColor: palette.grey[800],
                borderRadius: 5,
                padding: 16,
                ...typography.p4,
                fontFamily: 'OpenSans',
                color: palette.grey[50],

                '& .MuiTooltip-arrow': {
                    color: palette.grey[800],
                },
            },
        },
    },
    MuiSwitch: {
        styleOverrides: {
            root: {
                padding: 0,
                width: 42,
                height: 24,

                '& .MuiSwitch-switchBase': {
                    padding: 0,
                    margin: '3px',
                    transitionDuration: '300ms',
                    '&.Mui-checked': {
                        transform: 'translateX(16px)',
                        color: palette.common.white,
                        '& + .MuiSwitch-track': {
                            backgroundColor: palette.success.main,
                            opacity: 1,
                            border: 0,
                        },
                        '&.Mui-disabled + .MuiSwitch-track': {
                            opacity: 0.5,
                        },
                    },
                    '&.Mui-focusVisible .MuiSwitch-thumb': {
                        color: palette.success.main,
                        border: `6px solid ${palette.common.white}`,
                    },
                    '&.Mui-disabled .MuiSwitch-thumb': {
                        color: palette.grey[100],
                    },
                    '&.Mui-disabled + .MuiSwitch-track': {
                        opacity: 0.3,
                    },
                },
                '& .MuiSwitch-thumb': {
                    boxSizing: 'border-box',
                    width: 18,
                    height: 18,
                },
                '& .MuiSwitch-track': {
                    borderRadius: 26 / 2,
                    backgroundColor: palette.grey[400],
                    opacity: 1,
                },
            },
        }
    },
    MuiDrawer: {
        styleOverrides: {
            root: {
                '&.drawer': {
                    [`@media (max-width: ${breakpoints.values.md}px)`]: {
                        '& .MuiPaper-root': {
                            margin: 0,
                            width: '100%',
                            maxWidth: '100%',
                            borderTopLeftRadius: 7,
                            borderTopRightRadius: 7,
                            height: 'auto',
                            maxHeight: `calc(100% - 24px)`,
                            top: 'auto',
                            bottom: 0,
                        },
                    },

                    '& .drawerFooter': {
                        padding: 24,
                        borderTop: `1px solid ${palette.grey[200]}`,
                        backgroundColor: palette.grey[50],
                        '& .drawerFooterInner': {
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        },
                    },
                    '& .drawerHeader': {
                        padding: 24,
                        borderBottom: `1px solid ${palette.grey[200]}`,
                        backgroundColor: palette.grey[50],
                        position: 'relative',
                        '& .closeIcon': {
                            position: 'absolute',
                            right: 16,
                            top: 16,
                            cursor: 'pointer',
                        },

                        [`@media (max-width: ${breakpoints.values.md}px)`]: {
                            backgroundColor: palette.common.white,
                            padding: `24px 16px 0`,
                            borderBottom: 'none',
                            '& .closeIcon': {
                                display: 'none',
                            }
                        }
                    },
                    '& .drawerBody': {
                        padding: 24,
                        flexGrow: 1,
                        overflow: "auto",
                        "&::-webkit-scrollbar": {
                            display: "none"
                        },
                        [`@media (max-width: ${breakpoints.values.md}px)`]: {
                            padding: '0 16px 24px',
                        }
                    }
                },
            },
        },
    }
};