/**
 *  Check if blur event happened on target element
 *
 * @param e
 * @returns {Promise<void>}
 */
export const checkBlur = (e) => {
    const currentTarget = e.currentTarget;

    return new Promise((resolve, reject) => {
        setTimeout(() => {
            if (!currentTarget.contains(document.activeElement)) {
                resolve();
            }
        }, 0)
    })
};

export const getBreakPoint = (breakPoints) => {
    for (const [key, value] of Object.entries(breakPoints)) {
        const minWidthQuery = `(min-width: ${value['minWidth'] || 0}px)`;
        const maxWidthQuery = value['maxWidth'] ? ` and (max-width: ${value['maxWidth']}px)` : '';
        const mediaQuery = minWidthQuery + maxWidthQuery;

        if (window.matchMedia(mediaQuery).matches) {
            return key;
        }
    }

    return null;
};

export const redirect = (url) => {
    if (!/^https?:\/\//i.test(url)) {
        url = 'https://' + url; // Default to https
    }
    window.location.href = url;
}