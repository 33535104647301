import {guestRoutes} from 'config';
import * as api from 'services/api/api';
import {Resource, UserTransformer} from 'services/transform/transform';

export const authorize = async (email, password) => {
    await api.postRequest(guestRoutes.login, {email, password});
};

export const getAuthData = async () => {
    let user = null;
    let permissions = null;


    await api.getRequest(`/unm/members/me`, {include: ['avatar']}).then(resp => {
        user = Resource.transform(UserTransformer, resp.user.data, resp.user.meta);
        permissions = resp.permissions;
    });

    return {
        user: user,
        permissions: permissions,
    }
};

export const updateAuthUser = (data) => {
    data.append('_method', 'PUT');
    return api.postRequest(`/unm/members/me`, data);
};

export const logoutUser = async () => {
    await api.postRequest(guestRoutes.logout).catch(() => undefined);

    return true;
};

export const loginAudkenni = async (data) => {
    return await api.postRequest('/audkenni/login', data);
};

export const verifyAudkenni = async (data) => {
    return await api.postRequest('/audkenni/verify', data);
};

export const getLandingConfig = async (data) => {
    return await api.getRequest('/unm/landing_configs', data);
}