import React from 'react';
import useGlobalHook from 'components/UseGlobalHook/UseGlobalHook';
import {INITIAL_FILTERS} from '../staticData/staticData'

const initialState = {
    filters: INITIAL_FILTERS,
    systemParameters: [],
    properties: [],
    propertyTypes: [],
    regions: [],
};

export const actions = {
    setFilters: (store, filters, callBack) => {
        store.setState({filters}, callBack)
    },

    setSystemParameters: (store, systemParameters, callBack) => {
        store.setState({systemParameters}, callBack)
    },

    setProperties: (store, properties, callBack) => {
        store.setState({properties}, callBack)
    },

    setPropertyTypes:(store, propertyTypes, callBack) => {
        store.setState({propertyTypes}, callBack)
    },

    setRegions: (store, regions, callBack) => {
        store.setState({regions}, callBack)
    },
};

const globalHook = useGlobalHook(React, initialState, actions);

export default globalHook;