const styles = {
    votingModal: () => ({
        '& .MuiPaper-root': {
            maxWidth: 570,
            width: 570,
        }
    }),
    closeIcon: (theme) => ({
        position: 'absolute',
        top: theme.spacing(2),
        right: theme.spacing(2),
        zIndex: theme.zIndex['mobileStepper'],
        cursor: 'pointer',
    }),
}

export default styles;