import Transformer from "../Transformer";

class AnnouncementTransformer extends Transformer {
    static transform(announcement) {
        const {...result} = announcement;

        return result;
    }
}

export default AnnouncementTransformer;