import React, {useEffect} from 'react';
import ReactGA from "react-ga4";
import CssBaseline from '@mui/material/CssBaseline';
import {withRouter} from 'react-router-dom';

import Routes from './Routes/Routes';
import {gaMeasurementId} from 'config';
import Announcements from "@/providers/Announcements/Announcements";
import VotingsProvider from "providers/VotingsProvider/VotingsProvider";
import MenuProvider from "@/providers/MenuProvider/MenuProvider";
import PublicRoutes from "./Routes/PublicRoutes";
import NonUNM from "@/modules/NonUNM/NonUNM";
import {useAppState} from "./state/state";
import {getTranslations} from "services/api/translation/translation";
import Notifications from "@/components/Notifications/Notifications";
import {useAuth} from "@/providers/Auth/AuthProvider";
import {GlobalModalsRenderer} from "@/components/GlobalModals/GlobalModalsRenderer";
import Loader from "components/Loader/Loader";

const App = props => {
    const {accountInterface, user, nonUnmUser, isLoading} = useAuth();

    const [state, actions] = useAppState();

    useEffect(() => {
        ReactGA.initialize(gaMeasurementId);

        loadTranslations()
            .then(({savedTranslations}) => {
                actions.setSavedTranslations(savedTranslations);
            })

    }, []);

    useEffect(() => {
        document.title = accountInterface?.account?.name || '';
    }, [accountInterface]);

    const loadTranslations = async () => {
        const resp = await getTranslations();

        return {savedTranslations: resp.data.data};
    }

    if (isLoading || _.isEmpty(state.savedTranslations)) {
        return <Loader/>
    }

    if (user === null && nonUnmUser === null) {
        return (
            <CssBaseline>
                <Notifications/>
                <PublicRoutes/>
            </CssBaseline>
        )
    }

    if (nonUnmUser) {
        return (
            <CssBaseline>
                <NonUNM/>
            </CssBaseline>
        )
    }

    if (user) {
        return (
            <CssBaseline>
                <Notifications/>
                <VotingsProvider>
                    <Announcements>
                        <MenuProvider>
                            <Routes/>
                        </MenuProvider>
                    </Announcements>
                </VotingsProvider>
                <GlobalModalsRenderer/>
            </CssBaseline>
        )
    }
};

export default withRouter(App);