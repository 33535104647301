import getAccommodationItems from 'modules/Accommodation/menuItems/menuItems';
import getPvcItems from 'modules/PVC/menuItems/menuItems';
 import getUnionMemberItems from 'modules/UnionMember/menuItems/menuItems';
import getDkPlusItems from 'modules/DkPlus/menuItems/menuItems';
import styles from "@/layouts/components/Menu/styles/styles";
import {useAuth} from "@/providers/Auth/AuthProvider";
import md5 from 'crypto-js/md5';
import notification from "@/services/notification/notification";
import useTranslate from "@/components/UseTranslate/useTranslate";
import {default as clients} from "./unions";
import {useLang} from "providers/LangProvider/LangProvider";

const getApplicationPeriodsItems = (acm) => {
    const {checkAccess} = useAuth();
    const {lang} = useLang();

    if (_.isEmpty(acm)) {
        return [];
    }

    const {activeSeasons} = acm;

    if (!activeSeasons) {
        return [];
    }

    let activeApplicationPeriods = activeSeasons.flatMap((activeSeason) => activeSeason.activeApplicationPeriods);

    if (activeApplicationPeriods.length === 0) {
        return [];
    }

    let rentingApplicationPeriodItems = activeApplicationPeriods.map(period => {
        return {
            nodeId: `24-${period.id}`,
            label: period.name,
            no_translate: true,
            path: `/${lang}/acm/application_period/${period.id}`,
            show: true,
        }
    });

    return [
        {
            nodeId: '24',
            label: 'application_periods',
            sx: styles.itemRoot,
            show: checkAccess('acm_member_privileges'),
            items: rentingApplicationPeriodItems
        },
    ];
}

const getMenuItems = (menuData) => {
    const {checkAccess, isSpecificUnion, settings, user} = useAuth();
    const {allow_login_to_v1} = settings;
    const {t} = useTranslate();
    const {lang} = useLang();

    if (isSpecificUnion) {
        return [
            {
                nodeId: '1',
                label: 'requested_properties',
                path: `/${lang}/acm/properties/search`,
                sx: styles.itemRoot,
                show: checkAccess('acm_member_privileges')
            },
            {
                nodeId: '2',
                label: 'faq',
                path: `/${lang}/unm/faq`,
                sx: styles.itemRoot,
                show: checkAccess('unm_member_privileges'),
            },
        ]
    }

    return (
        [
            {
                nodeId: '1',
                label: 'home',
                path: `/${lang}`,
                sx: styles.itemRoot,
                show: true,
            },
            ...getAccommodationItems(),
            ...getApplicationPeriodsItems(menuData?.acm),
            ...getDkPlusItems(),
            ...getPvcItems(),
            {
                nodeId: '51',
                label: 'login_to_v1',
                sx: styles.itemRoot,
                show: !!allow_login_to_v1?.value,
                callback: () => {
                    const {ssn, account_id} = user;
                    const client = clients[account_id];

                    if (!client) {
                        notification.error(t('something_wrong'));
                    } else {
                        const url = `http://orlof.is/loginMysite.php?kt=${ssn}&token=${md5(ssn)}&client=${client}`;
                        window.open(url, "_blank");
                    }
                }
            },
            {
                nodeId: '6',
                label: 'discounts',
                sx: styles.itemRoot,
                path: `/${lang}/dsc/discounts`,
                show: checkAccess('dsc_member_privileges'),
            },
            {
                nodeId: '7',
                label: 'history',
                sx: styles.itemRoot,
                show: true,

                items: [
                    {
                        nodeId: '73',
                        label: 'travelers_check',
                        path: `/${lang}/pvc/history`,
                        show: checkAccess('pvc_member_privileges'),
                    },
                    {
                        nodeId: '74',
                        label: 'bookings_history',
                        path: `/${lang}/acm/history`,
                        show: checkAccess('acm_member_privileges'),
                    },
                    {
                        nodeId: '75',
                        label: 'applications_history',
                        path: `/${lang}/acm/applications_history`,
                        show: checkAccess('acm_member_privileges'),
                    },
                    {
                        nodeId: '76',
                        label: 'points',
                        path: `/${lang}/points/history`,
                        show: checkAccess('unm_member_privileges'),
                    }, {
                        nodeId: '77',
                        label: 'credit_history',
                        path: `/${lang}/credit/history`,
                        show: checkAccess('unm_member_privileges'),
                    },
                    {
                        nodeId: '78',
                        label: 'grant_history',
                        path: `/${lang}/dk-plus/grant_history`,
                        show: checkAccess('dk_plus_member_privileges'),
                    },
                    {
                        nodeId: '79',
                        label: 'salary_payments',
                        path: `/${lang}/dk-plus/salary_payments`,
                        show: checkAccess('dk_plus_member_privileges') && menuData.dk?.show_salary_page,
                    },
                ]
            },
            ...getUnionMemberItems(),
        ]
    )
}

export default getMenuItems;