import React from "react";

import VotingModal from "./components/VotingModal/VotingModal";

const ModalVotings = (props) => {
    const {votings, onClose, onVote} = props;

    if (_.isEmpty(votings)) {
        return null;
    }

    return (
        votings.map((voting, index) => {
            return (
                <VotingModal
                    key={index}
                    open={!voting.is_closed}
                    onClose={() => onClose(voting)}
                    onVote={() => onVote(voting)}
                    voting={voting}
                />
            )
        })
    )
}

export default ModalVotings;