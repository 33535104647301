import {Box, Divider, Typography} from "@mui/material";
import styles from './styles/styles'
import CloseIcon from "@/icons/Close";
import React from "react";
import Emails from "@/layouts/components/Header/components/ProfileInformation/components/Emails/Emails";
import PhoneNumbers
    from "@/layouts/components/Header/components/ProfileInformation/components/PhoneNumbers/PhoneNumbers";
import Addresses from "@/layouts/components/Header/components/ProfileInformation/components/Addresses/Addresses";
import useTranslate from "@/components/UseTranslate/useTranslate";
import BankInformation
    from "@/layouts/components/Header/components/ProfileInformation/components/BankInformation/BankInformation";
import Button from "@mui/material/Button";

const ProfileInformation = ({user, onCancel}) => {
    const {t} = useTranslate();

    return (
        <>
            <Box className={'drawerHeader'}>
                <Typography variant={'h4'} className={'header-title'}>{t('personal_banking_info')}</Typography>
                <CloseIcon color={'primary'} className={'closeIcon'} onClick={onCancel}/>
            </Box>
            <Box className={'drawerBody'}>
                <Box sx={styles.emailBox}>
                    <Emails/>
                    <Divider sx={styles.divider}/>
                    <PhoneNumbers/>
                    <Divider sx={styles.divider}/>
                    <Addresses/>
                    <Divider sx={styles.divider}/>
                    <BankInformation user={user}/>
                </Box>
            </Box>

            <Box className={'drawerFooter'}>
                <Box className={'drawerFooterInner'}>
                    <Button
                        variant='contained'
                        onClick={onCancel}
                        color={'secondary'}
                    >
                        <Typography variant={"p3Bold"}>
                            {t("close")}
                        </Typography>
                    </Button>
                    <Button
                        variant={'contained'}
                        onClick={onCancel}
                    >
                        <Typography variant={"p3Bold"}>
                            {t("save")}
                        </Typography>
                    </Button>
                </Box>
            </Box>
        </>
    )
}

export default ProfileInformation;