import React, {useState} from 'react';
import Box from '@mui/material/Box';
import ProfileDropDown from '../../../ProfileDropDown/ProfileDropDown';
import styles from './styles/styles';
import LangSwitcher from "@/layouts/components/Header/components/Heading/components/LangSwitcher/LangSwitcher";
import {useAuth} from "@/providers/Auth/AuthProvider";
import {Drawer, Switch} from "@mui/material";
import {useAppState} from "@/app/state/state";
import {supportUsers} from "@/config";
import ProfileInformation from "@/layouts/components/Header/components/ProfileInformation/ProfileInformation";

const RightSection = () => {
    const [showForm, setShowForm] = useState(false);
    const {user} = useAuth();
    const [state, actions] = useAppState();

    const handleDetailsButtonClick = () => {
        setShowForm(true);
    };

    return (
        <Box>
            <Box display={'flex'} alignItems={'center'}>
                {
                    supportUsers?.includes(user.ssn) &&
                    <Box>
                        <Switch
                            checked={state.translationEditMode}
                            aria-label={'Translations Mode'}
                            size="small"
                            onChange={() => actions.setTranslationEditMode(!state.translationEditMode)}
                        />
                    </Box>
                }

                <LangSwitcher/>

                <ProfileDropDown user={user} onDetailsButtonClick={handleDetailsButtonClick}/>
            </Box>
            {
                user &&
                <Drawer
                    anchor="right"
                    open={showForm}
                    onClose={() => setShowForm(false)}
                    className={'drawer'}
                    sx={styles.userDrawer}
                >
                    <ProfileInformation user={user} onCancel={() => setShowForm(false)}/>
                </Drawer>
            }
        </Box>
    )
};

export default RightSection;