import React, {createContext, useContext} from 'react';
import useAuthProviderData from "@/providers/Auth/hooks/useAuthProviderData";
import {withRouter} from "react-router-dom";

const AuthContext = createContext();

const AuthProvider = ({history, children}) => {
    const {
        isLoading,
        accountInterface,
        user,
        permissions,
        settings,
        landingConfigs,
        nonUnmUser,
        login,
        logout,
        updateUser,
        checkAccess,
        isSpecificUnion
    } = useAuthProviderData(history);

    return (
        <AuthContext.Provider
            value={{
                accountInterface,
                user,
                permissions,
                settings,
                landingConfigs,
                nonUnmUser,
                login,
                logout,
                updateUser,
                checkAccess,
                isSpecificUnion,
                isLoading
            }}>
            {children}
        </AuthContext.Provider>
    );
};

export default withRouter(AuthProvider);

export const useAuth = () => {
    return useContext(AuthContext);
};
