import theme from "@/themes/main/main";

const styles = {
    announcementContentBox: () => ({
        position: 'relative',
    }),
    announcementBox: (theme) => ({
        position: 'fixed',
        display: 'flex',
        justifyContent: 'space-between',
        top: 40,
        right: 40,
        zIndex: theme.zIndex.drawer,
        width: 360,
        backgroundColor: theme.palette.common.white,
        borderRadius: '5px',
        boxShadow: "0px 2px 16px 0px rgba(0, 0, 0, 0.08)",

        [theme.breakpoints.down('md')]: {
            marginRight: 'auto',
            marginLeft: 'auto',
            top: theme.spacing(3),
        },
    }),
    closeIconBox: (theme) => ({
        display: 'flex',
        justifyContent: 'flex-end',
        marginRight: theme.spacing(1),
        cursor: 'pointer',
    }),
    infoIcon: (theme) => ({
        borderTopLeftRadius: '5px',
        borderBottomLeftRadius: '5px',
        background: theme.palette.primary.main,
        padding: '16px 8px',

        '& svg': {
            color: theme.palette.common.white,
        }
    }),
    announcements: () => ({
        width: '100%',
        padding: '16px 8px',
    }),
    name: (theme) => ({
        color: theme.palette.grey[800],
        fontWeight: 700
    }),
    announcementsRootBlock: () => ({
       display: 'flex',
       flexDirection: 'row-reverse',
        justifyContent: 'space-between',
    }),
    content: (theme) => ({
        wordWrap: 'break-word',

        '& p': {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(2),
        }
    }),
    divider: (theme) => ({
        backgroundColor: theme.palette.common.black,
        height: '1px',
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(2),
    }),
    urlButton: () => ({
        width: '95%',
        marginTop: theme.spacing(1),
        padding: '10px 12px',
    }),
    announcementModal: () => ({
        '& .MuiPaper-root': {
            maxWidth: 570,
            width: 570,
        }
    }),
    announcementModalButtons: () => ({
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        gap: '64px',
        width: '100%'
    }),
    staticAnnouncementContent: () => ({
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
    })
}

export default styles;