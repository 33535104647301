import React from "react";

import {ModalVotings} from "components/Voting/Voting";
import {useVotings} from "providers/VotingsProvider/VotingsProvider";


const withVotings = (WrappedComponent) => {
    return (props) => {
        const {votings, closeVoting, handleVote} = useVotings();

        return (
            <>
                <WrappedComponent {...props}/>

                {
                    <ModalVotings
                        votings={votings}
                        onClose={closeVoting}
                        onVote={handleVote}
                    />
                }
            </>
        )
    }
}

export default withVotings;