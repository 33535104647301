import React, {useState} from "react";
import {Box, Button, Dialog, Typography} from "@mui/material";

import styles from "./styles/styles";
import {useLang} from "providers/LangProvider/LangProvider";
import {parseToHTML} from "services/formatters/parseToHtml/parseToHtml";
import CloseIcon from "icons/Close";

const VotingModal = (props) => {
    const {voting, open, onClose, onVote} = props;

    const [disabled, setDisabled] = useState(false);

    const {lang} = useLang();

    const getTranslation = (voting) => {
        const translation = voting?.translations.find(t => t.lang === lang);

        return ({
            title: translation?.title,
            content: translation?.content,
            btn_label: translation?.btn_label
        });
    }

    const redirectToUrl = async (url) => {
        setDisabled(true);
        await onVote();
        window.open(url, "_blank");
    }

    return (
        <Dialog
            open={open}
            maxWidth={false}
            className={'modal'}
            sx={styles.votingModal}
            onClose={onClose}
        >
            <Box className={'modalHeader'}>
                <Box className={'modalHeaderInner'}>
                    <Typography variant={'h4'}>{getTranslation(voting)?.title}</Typography>
                </Box>
            </Box>
            <Box className={'modalBody'}>
                <Box sx={styles.closeIcon}>
                    <CloseIcon color={'primary'} onClick={onClose}/>
                </Box>

                <Typography
                    variant={'p3'}
                    dangerouslySetInnerHTML={{__html: parseToHTML(getTranslation(voting)?.content)}}
                />
            </Box>
            <Box className={'modalFooter'}>
                <Box className={'modalFooterInner'}>
                    <Button
                        type={'button'}
                        variant={'contained'}
                        color={'primary'}
                        fullWidth
                        disabled={disabled}
                        onClick={() => redirectToUrl(voting?.url)}
                    >
                        <Typography variant={'p4'}>{getTranslation(voting)?.btn_label}</Typography>
                    </Button>
                </Box>
            </Box>
        </Dialog>
    )
}

export default VotingModal;