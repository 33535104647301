import {useEffect, useState} from "react";
import {getNonUnmAuthorizedUser} from "@/modules/NonUNM/services/api/auth/auth";
import {authorize, getAuthData, getLandingConfig, logoutUser} from "@/services/api/auth/auth";
import {getSettings} from "@/services/api/setting/setting";
import {getClient} from "@/services/api/client/client";
import storage from "@/services/storage/storage";
import {propertyRequestableAccountIDs} from "@/config";


const useAuthProviderData = (history) => {
    const [isLoading, setIsLoading] = useState(true);
    const [accountInterface, setAccountInterface] = useState(null);
    const [user, setUser] = useState(null);
    const [permissions, setPermissions] = useState(null);
    const [settings, setSettings] = useState(null);
    const [landingConfigs, setLandingConfigs] = useState(null);
    const [nonUnmUser, setNonUnmUser] = useState(null);


    useEffect(() => {
        storage.remove('interface');

        initialLoad()
            .then(({interfaceData, user, permissions, landingConfigs, settings, nonUnmAuthData}) => {
                setAccountInterface(interfaceData);
                setUser(user);
                setPermissions(permissions);
                setLandingConfigs(landingConfigs);
                setSettings(settings);
                setNonUnmUser(nonUnmAuthData);
            })
            .catch(() => {
                return history.push('/not_found');
            })
            .finally(() => {
                setIsLoading(prevState => !prevState);
            })
    }, []);

    const initialLoad = async () => {
        const interfaceResponse = await getClient();
        const interfaceData = interfaceResponse.data;

        if (_.isEmpty(interfaceData)) {
            throw new Error('Interface is not found');
        }

        if(interfaceData.expired) {
            history.push(`/${storage.get('lang')}/disabled_account`)
        }

        storage.set('interface', {id: interfaceData.id});

        const authUserData = await getAuthUserData(interfaceData);
        const nonUnmAuthData = await getAuthNonUnmData();

        return {interfaceData, ...authUserData, nonUnmAuthData};
    }


    const getAuthNonUnmData = async () => {
        let nonUnmAuthData = null;
        await getNonUnmAuthorizedUser()
            .then(response => nonUnmAuthData = response.data)
            .catch(() => {
            });

        return nonUnmAuthData;
    }

    const getAuthUserData = async (interfaceData) => {
        let user = null;
        let permissions = null;
        let settings = null;
        let landingConfigs = null;

        await getAuthData()
            .then(resp => {
                user = resp.user.data;
                permissions = resp.permissions;
            }).catch(() => {
            });

        if (user !== null) {
            await getSettings(interfaceData.account_id)
                .then(resp => settings = resp)
                .catch(() => {
                });

            await getLandingConfig()
                .then(resp => landingConfigs = resp?.data);
        }

        return {user, permissions, settings, landingConfigs};
    }

    const updateUser = (user) => {
        setUser(user);
    }

    const login = async (values) => {
        await authorize(values.email, values.password);

        const {user, permissions, settings, landingConfigs} = await getAuthUserData(accountInterface);

        setUser(user);
        setPermissions(permissions);
        setSettings(settings);
        setLandingConfigs(landingConfigs);
    };

    const logout = () => {
        logoutUser().finally(() => {
            location.assign(accountInterface.account.url);
        });
    };

    const checkAccess = (permissionName) => {
        return !!permissions.find(name => name === permissionName);
    }

    const isSpecificUnion = propertyRequestableAccountIDs?.includes(accountInterface?.account_id);

    return {
        isLoading,
        accountInterface,
        user,
        permissions,
        settings,
        landingConfigs,
        nonUnmUser,
        login,
        logout,
        updateUser,
        checkAccess,
        isSpecificUnion
    }
}

export default useAuthProviderData;