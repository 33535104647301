import React from "react";
import {VIEW_MODE} from "@/components/Announcement/staticData/staticData";
import {GeneralAnnouncements, StaticAnnouncements} from "@/components/Announcement/Announcement";
import {useAnnouncements} from "@/providers/Announcements/Announcements";


const withAnnouncements = (WrappedComponent) => {
    return (props) => {
        const {announcements, closeAnnouncement} = useAnnouncements();

        const notStaticAnnouncements = [...announcements].filter(
            ({view_mode, repetition, is_closed}) => view_mode === VIEW_MODE.modal && !is_closed
        );

        const staticAnnouncements = [...announcements].filter(
            ({view_mode, repetition, is_closed}) => view_mode === VIEW_MODE.alert && !is_closed
        );

        return (
            <>
                <StaticAnnouncements announcements={staticAnnouncements} onClose={closeAnnouncement}/>
                <WrappedComponent {...props}/>

                {
                    <GeneralAnnouncements
                        announcements={notStaticAnnouncements}
                        onClose={closeAnnouncement}
                    />
                }
            </>
        )
    }
}

export default withAnnouncements;