import React from 'react';
import {Box, Button, Collapse, Divider, Typography} from "@mui/material";
import styles from "modules/PVC/components/OfferDetailsModal/components/Offers/styles/styles";
import {kronaFormatter} from "@/services/formatters/kronaFromatter/kronaFormatter";
import useTranslate from "@/components/UseTranslate/useTranslate";
import moment from "moment";
import {displayDateFormat} from "@/config";
import {withRouter} from "react-router-dom";
import storage from "@/services/storage/storage";

const OfferByPeriod = (props) => {
    const {offers, periods, periodId, open} = props;

    const {t} = useTranslate();

    const goToPurchase = (price) => {
        props.history.push(`/${storage.get('lang')}/pvc/purchase?amount=${parseInt(price)}`);
    };

    if (_.isNull(periodId) || !offers) {
        return;
    }

    const offersTransformed = offers.filter((offer) => {
        return !_.isEmpty(offer?.prices.filter(offerPrice => !!offerPrice?.price && periodId === offerPrice.period_id))
    })

    return (
        <Box>
            {
                offersTransformed.map((offer, index) => {
                    return (
                        <Collapse in={open} timeout={'auto'}>
                            <Box key={index}>
                                <Divider sx={styles.divider}/>
                                <Box display={'flex'} sx={styles.offer} width={'100%'}>
                                    {
                                        offer?.prices.map((offerPrice, index) => {
                                            if (periodId !== offerPrice.period_id) {
                                                return;
                                            }

                                            const period = periods.find(period => period.id === offerPrice.period_id);
                                            const startDate = moment(period.start_date).format(displayDateFormat);
                                            const endDate = moment(period.end_date).format(displayDateFormat);

                                            return (
                                                <Box
                                                    sx={styles.offerBox}
                                                    key={index}
                                                >
                                                    <Box>
                                                        <Box sx={styles.periodTitleBox}>
                                                            <Typography variant={'p3Bold'}>
                                                                {offer.name}
                                                            </Typography>
                                                            {
                                                                offerPrice.price &&
                                                                <Typography variant={'p3'} sx={styles.price}>
                                                                    {kronaFormatter(offerPrice.price, true)}
                                                                </Typography>
                                                            }
                                                        </Box>
                                                        <Box display={'flex'}>
                                                            <Typography sx={styles.periodTitle} variant={'p4Bold'}>
                                                                {period?.title}:
                                                            </Typography>
                                                            <Typography variant={'p4'} sx={styles.periodDates}>
                                                                {startDate} - {endDate}
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                    <Button
                                                        variant={'contained'}
                                                        color={'primary'}
                                                        sx={styles.buyBtn}
                                                        onClick={() => goToPurchase(offerPrice.price)}
                                                    >
                                                        <Typography variant={'p3'}>
                                                            {t('buy')}
                                                        </Typography>
                                                    </Button>
                                                </Box>
                                            );
                                        })
                                    }
                                </Box>
                            </Box>
                        </Collapse>
                    );
                })
            }
        </Box>
    )
}

export default withRouter(OfferByPeriod);