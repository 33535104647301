import React, {useEffect, useState} from 'react';
import DetailContent from "@/modules/PVC/components/OfferDetailsModal/components/DetailContent/DetailContent";
import {Box, Paper} from "@mui/material";

const DetailsPage = () => {
    const [offer, setOffer] = useState();

    useEffect(() => {
        window.removeEventListener("message", receiveMessage);
        window.addEventListener("message", receiveMessage);
        parent.postMessage("loaded", "*");
    }, []);

    const receiveMessage = e => {
        setOffer(e.data);
    };

    if (!offer) {
        return null;
    }

    return (
        <Paper sx={{maxHeight: 540, overflow: 'auto'}}>
            <Box width={760}>
                <DetailContent item={offer}/>
            </Box>
        </Paper>
    );
};

export default DetailsPage;