import React, {createContext, useEffect, useState} from "react";
import {useAuth} from "@/providers/Auth/AuthProvider";
import {getSeasons} from "@/modules/Accommodation/services/api/renting/renting";
import {Resource} from "@/services/transform/resource/resource";
import {RentingTransformer} from "@/modules/Accommodation/services/transform/resource/transformers/transformers";

export const MenuContext = createContext({});

const MenuProvider = ({children}) => {
    const {settings} = useAuth();
    const [menuData, setMenuData] = useState({});

    useEffect(() => {
        getMenuData(settings)
            .then(result => setMenuData(result))
    }, []);

    const getMenuData = async (settings) => {
        const menuData = {
            acm: {}
        };
        
        if (isAcmModuleEnabled(settings)) {
            let activeSeasons = [];

            await getAcmActiveSeasons()
                .then(data => {
                    activeSeasons = data
                }).catch(() => {
                });
            
            menuData.acm.activeSeasons = activeSeasons;
        }

        return menuData;
    }

    const getAcmActiveSeasons = async () => {
        const data = {
            include: ['activeApplicationPeriods']
        };

        const response = await getSeasons(data);
        const seasons = Resource.transform(RentingTransformer, response.data, response.meta).data;

        return seasons.filter(({activeApplicationPeriods}) => {
            return !!activeApplicationPeriods.length
        });
    }

    const isAcmModuleEnabled = (settings) => {
        return Object.keys(settings).some(setting => setting.includes('acm_')); // TODO accommodation module enabled/disabled issue
    }
    

    return (
        <MenuContext.Provider value={menuData}>
            {children}
        </MenuContext.Provider>
    )
}

export default MenuProvider; 