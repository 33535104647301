import * as api from 'services/api/api';

export const getVotings = data => {
    return api.getRequest('/unm/member_votings', data);
};

export const updateVotingVoted = (id, data) => {
    return api.patchRequest(`/unm/votings/${id}/voted`, data);
};

export const updateVotingSeen = (id, data) => {
    return api.patchRequest(`/unm/votings/${id}/seen`, data);
};