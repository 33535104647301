export const baseUrl = 'https://api.demo.orlof.is/api';

export const guestRoutes = {
    login: '/login',
    refreshToken: '/login/refresh',
    logout: '/logout'
};

export const languages = ['en', 'is'];

export const defaultLang = 'is';

export const dateFormat = 'YYYY-MM-DD';

export const dateTimeFormat = 'YYYY-MM-DD HH:mm';

export const displayDateFormatWithTime = 'DD.MM.YYYY HH:mm';

export const displayDateFormat = 'DD.MM.YYYY';

export const googleMapURL = "https://maps.googleapis.com/maps/api/js?key=AIzaSyBIi_HiRDc_fOklAPmDqYZ4-mMEYjk4LrI&v=3.exp";

export const islandisLoginUrl = 'https://innskraning.island.is/?id=test.apmedia.is';

export const supportUsers = ['2609795829', '1702785779', '1209783309'];

export const propertyRequestableAccountIDs = [138];

export const gaMeasurementId = 'G-8P2G4KQTLM';