import React from 'react';
import * as Sentry from '@sentry/react';
import * as Yup from 'yup';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import useTranslate from "@/components/UseTranslate/useTranslate";
import ActiveForm, {ActiveInput} from "@/components/ActiveForm/ActiveForm";
import styles from "@/pages/Login/components/LoginForm/styles/styles";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import {useAuth} from "@/providers/Auth/AuthProvider";
import {withRouter} from "react-router-dom";

const LoginForm = (props) => {
    const {login} = useAuth();

    const {t} = useTranslate();

    const onSuccess = async () => {
        return props.history.push('/', {checkCustomUrl: true});
    }

    return (
        <Box sx={styles.mainBox}>
            <Box sx={styles.titleBox}>
                <Typography sx={styles.title}>
                    {t('title_admin_login')}
                </Typography>
            </Box>
            <ActiveForm
                initialValues={{
                    email: '',
                    password: '',
                }}

                validationSchema={Yup.object().shape({
                    email: Yup.string().email('invalid').required('required'),
                    password: Yup.string().min(6, 'min').required('required'),
                })}

                onSubmit={login}
                onSuccess={onSuccess}

                onError={(createMode, {setErrors}, values, errors, resp) => {
                    setErrors({
                        password: 'wrong_credentials'
                    });

                    if (process.env.NODE_ENV === 'production') {
                        Sentry.captureMessage('Authentication Failed', resp);
                    }
                }}

                render={(form) => {
                    const {values, errors, touched, isSubmitting, handleSubmit} = form;

                    return (
                        <form onSubmit={handleSubmit}>
                            <Box sx={styles.formBox}>
                                <Box sx={styles.inputBox}>
                                    <Box p={3}>
                                        <ActiveInput
                                            type="email"
                                            name="email"
                                            placeholder={t('placeholder_email_address')}
                                            label={t('email')}
                                            error={!!(touched.email && errors.email)}
                                            value={values.email}
                                            margin='normal'
                                            onError={error => t(`error_email_${error}`)}
                                            fullWidth
                                        />
                                    </Box>
                                    <Box p={3}>
                                        <ActiveInput
                                            type="password"
                                            name="password"
                                            placeholder={t('placeholder_password')}
                                            error={!!(touched.password && errors.password)}
                                            value={values.password}
                                            label={t('password')}
                                            onError={error => t(`error_password_${error}`, {min: 6})}
                                            margin='normal'
                                            fullWidth
                                        />
                                    </Box>
                                </Box>
                                <Divider/>
                                <Box sx={styles.buttonBox}>
                                    <Button fullWidth={true} disabled={isSubmitting} variant="contained" color="primary"
                                            type="submit">
                                        {t('login')}
                                    </Button>
                                </Box>
                            </Box>
                        </form>
                    )
                }}/>
        </Box>
    )
};

export default withRouter(LoginForm);

